/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */

import React from "react";
import Image from "core/components/Image";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as CONSTANTS from "app/AppConfig/constants";
import oResourceBundle from "app/i18n/";
import "./index.scss";
import huaweiappgallery from "../../../resources/assets/footer/huawei-appgallery.svg";
import androidTV from "../../../resources/assets/footer/androidTv.svg";
import appleTv from "../../../resources/assets/footer/appleTv.svg";
import apple1 from "../../../resources/assets/footer/apple1.svg";
import googleplayen from "../../../resources/assets/footer/googleplayen.svg";
import GooglePlayStore from "../../../resources/assets/footer/playstore.svg";
import AppStores from "../../../resources/assets/footer/AppStores.svg";
import { isMobile } from "react-device-detect";

class AppFooter extends React.Component {
  /**
   * Component Name - AppFooter
   * It is a render method of Footer Component of Website.It will render the footer in the UI.
   * @param { null }
   * @returns { Object }
   */
  render() {
    const className = this.props.className + " app-footer";
    return (
      <React.Fragment>
        <footer className={className}>
          <div className="footer-top">
            <div className="footer-top-left">
              {
                <div className="links">
                  <div>
                    <Link
                      aria-label={oResourceBundle.about}
                      key={"about"}
                      className="router-link hover-link"
                      to={`/${this.props.locale}/${CONSTANTS.ABOUT}${this.props.locale}`}
                    >{`${oResourceBundle.about}`}</Link>
                  </div>
                  <div>
                    <Link
                      aria-label={oResourceBundle.privacy_policy}
                      key={"privacy"}
                      className="router-link hover-link"
                      to={`/${this.props.locale}/${CONSTANTS.PRIVACY_POLICY}${this.props.locale}`}
                    >
                      {`${oResourceBundle.privacy_policy}`}
                    </Link>
                  </div>
                  <div>
                    <Link
                      aria-label={oResourceBundle.terms}
                      key={"terms"}
                      className="router-link hover-link"
                      to={`/${this.props.locale}/${CONSTANTS.TERMS_OF_USE}
                     `}
                    >{`${oResourceBundle.terms}`}</Link>
                  </div>
                  <div>
                    <Link
                      aria-label={oResourceBundle.contact_us_title}
                      key={"contactUs"}
                      className="router-link hover-link"
                      to={`/${this.props.locale}/${CONSTANTS.CONTACT_US}${this.props.locale}`}
                    >
                      {`${oResourceBundle.contact_us_title}`}
                    </Link>
                  </div>
                </div>
              }
            </div>
            <div className="footer-top-right">
              {this.props.locale === "en" ? (
                <div className="download-app">
                  <span>{oResourceBundle.app_available_on}</span>
                  <a
                    href="https://apps.apple.com/in/app/z5-weyyak-%D9%88%D9%8A%D8%A7%D9%83/id1226514781"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={oResourceBundle.available_app_store}
                  >
                    <img
                      src={apple1 }
                      alt="ic-appstore"
                      hideFallback={true}
                      style={ isMobile ? { width: "30px", height: "40px"}: {  }}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.tva.z5"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={oResourceBundle.available_google_play}
                  >
                    <img
                      // src={
                      //   this.props.locale === "en"
                      //     ? googleplayen
                      //     : GooglePlayStore
                      // }
                      src={googleplayen}
                      alt={oResourceBundle.available_google_play}
                      hideFallback={true}
                      style={ isMobile ? { width: "30px", height: "40px"}: {  }}
                    />
                  </a>
                  <a
                    href="https://appgallery.huawei.com/app/C100128091"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="huawei"
                    aria-label={oResourceBundle.available_google_play}
                  >
                    <img
                      src={huaweiappgallery}
                      alt="ic-appstore"
                      hideFallback={true}
                      style={ isMobile ? { width: "30px", height: "40px"}: {  }}
                    />
                  </a>
                  <a
                    // href="https://appgallery.huawei.com/app/C100128091"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={oResourceBundle.available_google_play}
                  >
                    <img src={appleTv} alt="ic-appstore" hideFallback={true} style={ isMobile ? { width: "30px", height: "40px"}: {  }} />
                  </a>
                  <a
                    // href="https://appgallery.huawei.com/app/C100128091"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={oResourceBundle.available_google_play}
                  >
                    <img
                      src={androidTV}
                      alt="ic-appstore"
                      hideFallback={true}
                      style={ isMobile ? { width: "30px", height: "40px"}: { width: "73px", height: "22px" }}
                    />
                  </a>
                </div>
              ) : (
                <div className="download-app">
                  <span>{oResourceBundle.app_available_on}</span>
                  <a
                    // href="https://appgallery.huawei.com/app/C100128091"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={oResourceBundle.available_google_play}
                  >
                    <img
                      src={androidTV}
                      alt="ic-appstore"
                      hideFallback={true}
                      style={ isMobile ? { width: "30px", height: "40px"}: { width: "73px", height: "22px" }}
                    />
                  </a>
                  <a
                    // href="https://appgallery.huawei.com/app/C100128091"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={oResourceBundle.available_google_play}
                  >
                    <img src={appleTv} alt="ic-appstore" hideFallback={true}  style={ isMobile ? { width: "30px", height: "40px"}: {  }}/>
                  </a>
                  <a
                    href="https://appgallery.huawei.com/app/C100128091"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="huawei"
                    aria-label={oResourceBundle.available_google_play}
                  >
                    <img
                      src={huaweiappgallery}
                      alt="ic-appstore"
                      hideFallback={true}
                      style={ isMobile ? { width: "30px", height: "40px"}: {  }}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.tva.z5"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={oResourceBundle.available_google_play}
                  >
                    <img
                      // src={
                      //   this.props.locale === "en"
                      //     ? googleplayen
                      //     : GooglePlayStore
                      // }
                      src={GooglePlayStore}
                      alt={oResourceBundle.available_google_play}
                      hideFallback={true}
                      style={ isMobile ? { width: "30px", height: "40px"}: {  }}
                    />
                  </a>

                  <a
                    href="https://apps.apple.com/in/app/z5-weyyak-%D9%88%D9%8A%D8%A7%D9%83/id1226514781"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={oResourceBundle.available_app_store}
                  >
                    <img
                      src={ AppStores }
                      alt="ic-appstore"
                      hideFallback={true}
                      style={ isMobile ? { width: "30px", height: "40px"}: {  }}
                    />
                  </a>
                </div>
              )}

              {this.props.locale === "en" ? (
                <div className="follow-us">
                  <a
                    href="https://www.facebook.com/weyyakcom"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={oResourceBundle.facebook}
                    className="facebook"
                  ></a>
                  <a
                    href="https://www.instagram.com/z5weyyak/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={oResourceBundle.instagram}
                    className="instagram"
                  ></a>
                  <a
                    href="https://www.threads.net/@z5weyyak"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={oResourceBundle.linkedin}
                    className="threads"
                  ></a>
                  <a
                    href="https://twitter.com/Z5weyyak"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={oResourceBundle.twitter}
                    className="twitter"
                  ></a>
                  <a
                    href="https://www.youtube.com/weyyakcom"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={oResourceBundle.youtube}
                    className="youtube"
                  ></a>
                  <a
                    href="https://www.tiktok.com/@weyyak?lang=en"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={oResourceBundle.music}
                    className="tiktok"
                  ></a>
                </div>
              ) : (
                <div className="follow-us">
                  <a
                    href="https://www.tiktok.com/@weyyak?lang=en"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={oResourceBundle.music}
                    className="tiktok"
                  ></a>
                  <a
                    href="https://www.youtube.com/weyyakcom"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={oResourceBundle.youtube}
                    className="youtube"
                  ></a>
                  <a
                    href="https://twitter.com/Z5weyyak"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={oResourceBundle.twitter}
                    className="twitter"
                  ></a>
                  <a
                    href="https://www.threads.net/@z5weyyak"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={oResourceBundle.linkedin}
                    className="threads"
                  ></a>
                  <a
                    href="https://www.instagram.com/z5weyyak/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={oResourceBundle.instagram}
                    className="instagram"
                  ></a>
                  <a
                    href="https://www.facebook.com/weyyakcom"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={oResourceBundle.facebook}
                    className="facebook"
                  ></a>
                </div>
              )}
            </div>
          </div>
          <div className="footer-bottom">
            <div>
              {oResourceBundle.copyright}
              {/* <span>©</span>  */}
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

/**
 * Component - AppFooter
 * method that maps state to props.
 * @param {Object} state - state from redux store.
 * @return {Object} - state mapped to props
 */
const mapStateToProps = (state) => {
  return {
    aStaticMenuItems: state.aStaticMenuItems,
    platformConfig: state.platformConfig,
    locale: state.locale,
  };
};

export default connect(mapStateToProps)(AppFooter);
