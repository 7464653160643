import React from "react";
import BaseContainer from "core/BaseContainer/";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";

class Recaptcha extends BaseContainer {
  constructor(props) {
    super(props);
    this.state = {
      callback: "not fired",
      load: false,
    };
    this._reCaptchaRef = React.createRef();
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ load: true });
    });
  }
  RecaptchaonChange = (recaptcha_token) => {
    this.props.isVerified(recaptcha_token);
  };

  render() {
    const { load } = this.state || {};
    let Domine_Host = window.location.host;
    let lang = this.props.locale == "en" ? "en" : "ar";
    let siteKey = "6LdNV9siAAAAAF1mtFDw0nzXNmRRcoE8MuRTVgpS";

    if (Domine_Host.includes("localhost")) {
      siteKey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"; // TestKey provided by Google
    } else if (Domine_Host.includes("webqa.weyyak.com")) {
      siteKey = "6Le90wgdAAAAAPB4Hc8tjGzskE3YWRzE22T51JQL";
    } else if (Domine_Host.includes("webuat.weyyak.com")) {
      siteKey = "6Lc81AgdAAAAAArfSvIyatQkDEg4paJzfc6WTtg7";
    }

    return (
      <div className="Recaptcha">
        {load && (
          <ReCAPTCHA
            ref={this._reCaptchaRef}
            sitekey={siteKey}
            onChange={this.RecaptchaonChange}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.locale,
  };
};

export default connect(mapStateToProps, null)(Recaptcha);
