/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */

import React from 'react';
import RootContainer from 'core/RootConatiner/';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actionTypes from 'app/store/action/';
import RoutComponent from 'app/Routes/';
import { Link } from 'react-router-dom';
import Button from 'core/components/Button/';
import * as routeNames from 'app/Routes/RouteNames';
import * as constants from "../../../AppConfig/constants";
import * as CONSTANTS from 'app/AppConfig/constants';
import AppHeader from 'app/views/components/AppHeader';
import Spinner from 'core/components/Spinner';
import AppMenu from 'app/views/components/AppMenu';
import AppFooter from 'app/views/components/AppFooter';
import ManageCookies from 'app/views/components/ManageCookies';
import { ENABLE_COUNTRY_QUERY_PARAM } from 'app/AppConfig/features';
import oResourceBundle from 'app/i18n/';
import oResourceBundleError from 'app/AppConfig/Error/';
import HandlerContext from 'app/views/Context/HandlerContext';
import { getDirection } from 'app/utility/common';
import { sendEvents } from 'core/GoogleAnalytics/';
import Logger from 'core/Logger';
import * as common from 'app/utility/common';
import { Toaster } from 'core/components/Toaster/';
import { toast } from "core/components/Toaster/";
import url from 'url';
import logo from '../../../resources/assets/weyyak-logo1.svg';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import circle from "../../../../app/resources/assets/common/circlepopup.svg"
import { CleverTap_CustomEvents, CleverTap_privacy } from 'core/CleverTap'

import './index.scss';
import { localsName } from 'ejs';

class AppBody extends RootContainer {
  MODULE_NAME = 'AppBody';
  bLogoClicked = false;
  /**
   * Represents App Body.
   * @constructor
   * @param {Object} props - Properties of the object.
   */
  constructor(props) {
    super(props);
    let pathArray = props.location.pathname.split('-');
    let isCookieSite=(pathArray[pathArray.length-2]==='android') ? true : false;
    let isPrivacySite=(pathArray[pathArray.length-2]==='android') ? true : false;
    let isTermSite=(pathArray[pathArray.length-2]==='android') ? true : false;
    this.state = {
      showMenu: false,
      showSearchInput: false,
      playerScreenVisible: false,
      showUserMenu: false,
      userInputText: '',
      errorOccured: false,
      geoBlock: false,
      cookieSettings: false,
      showEssential: false,
      showPerformance: false,
      showAdvertising: false,
      isCookieSite:isCookieSite,
      isPrivacySite:isPrivacySite,
      isTermSite:isTermSite
   };
    this.bMenuLoaded = false;      
  }

  /**
   * Component Name - AppBody
   * Executes when component mounted to DOM.
   * @param null
   */
  componentDidMount() {
    //logic for user not loggedin  when user enters into plans-description page it  has redirect to login Page don't delete
    if(!common.isUserLoggedIn()){
      const currentPath = window.location.pathname;
      if(currentPath.includes(CONSTANTS.PLANS_DESCRIPTION) || currentPath.includes(CONSTANTS.PLANS)){
        this.props.history.push(`${this.props.match.url}/${CONSTANTS.LOGIN}/`);
      }
    }
  
    let sLanguageCode = this.props.match.params.langcode;
    const url_parts = url.parse(this.props.location.search, true);
    const query = url_parts.query;

    //if language code is not valid
    if (
      !sLanguageCode ||
      sLanguageCode.length > 2 ||
      (sLanguageCode !== CONSTANTS.AR_CODE && sLanguageCode !== CONSTANTS.EN_CODE)
    ) {
      this.props.history.location.pathname !== CONSTANTS.HOME_PATH && this.props.history.push('/');
      //Set deafult locale
      sLanguageCode = this.props.locale;
    }
    //Get platform configs and update language code based on URL
    oResourceBundle.setLanguage(sLanguageCode);
    oResourceBundleError.setLanguage(sLanguageCode);
    this.props.fnChangeAppLocale(sLanguageCode);
    this.props.fnFetchPlatformConfig(
      sLanguageCode,
      ENABLE_COUNTRY_QUERY_PARAM ? query.country : null,
      this.platformConfigSuccess.bind(this),
      this.platformConfigFailed.bind(this)
    );
    //Idle Time calculation
    this.idleTime = 0;

    // Clevertap user Privacy 
    CleverTap_privacy()
  }

  platformConfigSuccess() {
    Logger.log(this.MODULE_NAME, 'platformConfigSuccess');
    this.setState({
      errorOccured: false
    });
    let Ccode= this.props.countryCode ? this.props.countryCode : localStorage.getItem('country') 
    this.props.fnHeaderMenu(
      this.props.locale,
      Ccode,
      oReponse => {

        //Success
        if (oReponse.code === 404) {
          common.showToast(
            CONSTANTS.GENERIC_TOAST_ID,
            oResourceBundle.payment_system_error,
            toast.POSITION.BOTTOM_CENTER
          );
        }
      },
      oError => {
        //Failed
        common.showToast(
          CONSTANTS.GENERIC_TOAST_ID,
          oResourceBundle.payment_system_error,
          toast.POSITION.BOTTOM_CENTER
        );
      })
  }

  platformConfigFailed() {
    Logger.log(this.MODULE_NAME, 'platformConfigFailed');
    this.setState({
      errorOccured: true
    });
  }
  /**
   * Component Name - AppBody
   * Update cookie with new time
   * @param {null}
   */
  fnUpdateCookie() {
    const oUserObj = common.getServerCookie(CONSTANTS.COOKIE_USER_OBJECT)
      ? JSON.parse(common.getServerCookie(CONSTANTS.COOKIE_USER_OBJECT))
      : null;
    const oUserToken = common.getServerCookie(CONSTANTS.COOKIE_USER_TOKEN)
      ? JSON.parse(common.getServerCookie(CONSTANTS.COOKIE_USER_TOKEN))
      : null;
    const oRememberMe = common.getCookie(CONSTANTS.COOKIE_REMEMBER_ME)
      ? JSON.parse(common.getCookie(CONSTANTS.COOKIE_REMEMBER_ME))
      : null;
    if (oUserObj && oUserToken && oRememberMe && !oRememberMe.isRemeberMe) {
      common.setCookie(
        CONSTANTS.COOKIE_USER_OBJECT,
        JSON.stringify(oUserObj),
        CONSTANTS.COOKIES_TIMEOUT_NOT_REMEMBER
      );
      common.setCookie(
        CONSTANTS.COOKIE_USER_TOKEN,
        JSON.stringify(oUserToken),
        CONSTANTS.COOKIES_TIMEOUT_NOT_REMEMBER
      );
      common.setCookie(
        CONSTANTS.COOKIE_REMEMBER_ME,
        JSON.stringify(oRememberMe),
        CONSTANTS.COOKIES_TIMEOUT_NOT_REMEMBER
      );
    }
  }

  /**
   * Component Name - AppBody
   * invoked right before calling the render method, both on the initial mount and on subsequent updates.
   * @param {object} props - props
   * @param {object} state - states
   */
  static getDerivedStateFromProps(props, state) {
    return null;
  }

  /**
   * Toggles visibility of filter dropdown
   * @param {Object} oEvent
   */
  filterShowToggleUserMenu(oEvent) {
    if (this.state.showUserMenu) {
      this.setState({ showUserMenu: false });
    } else {
      //Make it true for activating dropdown menu
      this.setState({ showUserMenu: false });
    }
    oEvent.stopPropagation();
  }

  /**
   * Component Name - AppBody
   * Executes when component mounted to DOM.
   * @param {object} nextProps - properties
   * @param {object} nextState - states
   * @returns {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  /**
   * Component Name - AppBody
   * Executes when component updated after props or state change
   * @param {object} prevProps - Previous props
   * @param {object} prevState - Previous states
   */
  componentDidUpdate(prevProps, prevState) {
    const nextLocale = this.props.locale === CONSTANTS.EN_CODE ? CONSTANTS.AR_CODE : CONSTANTS.EN_CODE; 
    //dispatch menu items fetch only if menu are not loaded
    //And new props locale is changed
    if (
      (this.props.platformConfig && !this.props.aMenuItems && !this.bMenuLoaded) ||
      (this.props.platformConfig && this.props.locale !== prevProps.locale)
    ) {
      this.props.fnFetchMenuItems(this.props.locale);
      this.bMenuLoaded = true;
      let Ccode= this.props.countryCode ? this.props.countryCode : localStorage.getItem('country') 

      this.props.fnHeaderMenu(
        this.props.locale,
        Ccode,
        oReponse => {

          //Success
          if (oReponse.code === 404) {
            common.showToast(
              CONSTANTS.GENERIC_TOAST_ID,
              oResourceBundle.payment_system_error,
              toast.POSITION.BOTTOM_CENTER
            );
          }
        },
        oError => {
          //Failed
          common.showToast(
            CONSTANTS.GENERIC_TOAST_ID,
            oResourceBundle.payment_system_error,
            toast.POSITION.BOTTOM_CENTER
          );
        })
    }
    //on back change app direction
    if (
      this.props.history.action === 'POP' &&
      this.props.match.params.langcode !== prevProps.match.params.langcode
    ) {
      let sLocale = nextLocale;
      if (!this.props.match.params.langcode) sLocale = CONSTANTS.AR_CODE;
      this.props.fnChangeAppDirection(sLocale);
      //Change app language
      oResourceBundle.setLanguage(sLocale);
      oResourceBundleError.setLanguage(sLocale);

    }
    if (this.props.countryCode !== prevProps.countryCode) {
      const geoBlock = common.isGeoBlocked(this.props.countryCode);
      this.setState({
        geoBlock: geoBlock
      });

    }
  }

  /**
   * Component Name - AppBody
   *  Language change handler.
   * @param {object} oEvent - Event hanlder
   */
  onLanguageButtonClickHandler = (oEvent) => {
    const pathname = decodeURIComponent(window.location.pathname);
    const aPathnameParams = pathname.split('/');
    let newPathName = '';
    const nextLocale = this.props.locale === CONSTANTS.EN_CODE ? CONSTANTS.AR_CODE : CONSTANTS.EN_CODE;
    if (aPathnameParams.indexOf(this.props.locale) > -1 && this.props.match.params.langcode) {
      aPathnameParams[aPathnameParams.indexOf(this.props.locale)] = nextLocale;

      //Check of videocontent page
      if (this.props.match.path === routeNames.VIDEO_CONTENT) {
        //4th index is name
        aPathnameParams[4] = window.sTranslatedTitle.toLowerCase();
      } else if (this.props.match.path === routeNames.PLAYER) {
        //5th index is name
        aPathnameParams[5] = window.sTranslatedTitle.toLowerCase();
      }
      newPathName = aPathnameParams.join('/');
    } else if (!this.props.match.params.langcode) {
      Logger.log(this.MODULE_NAME, CONSTANTS.HOME_PATH);
      newPathName = CONSTANTS.HOME_PATH + nextLocale;
    }

    //Change app language
    oResourceBundle.setLanguage(nextLocale);
    oResourceBundleError.setLanguage(nextLocale);
    //Set locale to redux store
    this.props.history.push(newPathName);
    this.props.fnChangeAppDirection(nextLocale);

    //Send analytics event
    sendEvents(
      CONSTANTS.CHANGE_LANGUAGE,
      nextLocale === CONSTANTS.AR_CODE ? CONSTANTS.AR_ACTION : CONSTANTS.EN_ACTION
    );
  };

  /**
   * Component Name - AppBody
   *  Menu Button click handler.
   * @param {object} oEvent - Event hanlder
   */
  onMenuButtonClick = (oEvent) => {
    this.setState((prevState) => ({ showMenu: !this.state.showMenu }));
    this.fnHideSearchInput();
    oEvent.stopPropagation();
  };

  /**
   * Component Name - AppBody
   * Search Button click handler.
   * @param {object} oEvent - Event hanlder
   */
  onSearchButtonClick(oEvent) {
    this.setState((prevState) => ({
      showSearchInput: !this.state.showSearchInput
    }));
    this.fnCloseMenu();
    oEvent.stopPropagation();
  }
  /**
   * Component Name - AppBody
   * Hide Menu handler
   * @param null
   * @returns {undefined}
   */
  fnCloseMenu() {
    this.setState({ showMenu: false });
  }

  /**
   * Component Name - AppBody
   * Hide Search Input
   * @param null
   * @returns {undefined}
   */
  fnHideSearchInput() {
    this.props.fnClearUserSearchData();
    this.setState({ showSearchInput: false, userInputText: '' });
  }

  /**
   * Component Name - AppBody
   * Overlay click handler.
   * @param null
   * @returns {undefined}
   */
  onOverlayClick = () => {
    this.setState({
      showMenu: false,
      showSearchInput: false,
      showUserMenu: false
    });
  };

  /**
   * Component Name - AppBody
   * App body click handler.
   * @param null
   * @returns {undefined}
   */
  onAppBodyClicked() {
    this.state.showMenu && this.fnCloseMenu();
    this.state.showSearchInput && this.fnHideSearchInput();
    this.state.showUserMenu && this.setState({ showUserMenu: false });
    //Clear search items
    this.props.fnClearSearchItems();
  }

  /**
   * Component Name - AppBody
   * Search input click handler.
   * @param {object} oEvent - Event hanlder
   * @returns {undefined}
   */
  onSearchInputClicked(oEvent) {
    oEvent.stopPropagation();
  }

  /**
   * Component Name - AppBody
   * Sign in / Register click handler.
   * @param {object} oEvent - Event hanlder
   * @returns {undefined}
   */
  onSignInClick() {
    if (common.isUserLoggedIn()) {
      common.fnNavTo.call(this, `/${this.props.locale}/${CONSTANTS.MY_ACCOUNT}`);
    } else {
      this.setResumePath();
      common.fnNavTo.call(this, `/${this.props.locale}/${CONSTANTS.LOGIN}`);
    }
  }

  setResumePath() {
    const currentPath = this.getCurrentPath();
    const fromPlayer =
      window.location.href.includes(CONSTANTS.SUBSCRIPTION_TO_WATCH_AD) ||
      window.location.href.includes(CONSTANTS.SUBSCRIPTION_TO_WATCH);
    let resumePath;
    if (fromPlayer === true) {
      resumePath = this.props.sResumePagePath;
    } else if (
      !currentPath.includes('login') &&
      !currentPath.includes('sign-up') &&
      !currentPath.includes('plans') &&
      !currentPath.includes('adyen') &&
      !currentPath.includes('transaction') &&
      !currentPath.includes('payment')
    ) {
      resumePath = currentPath;
    } else {
      resumePath = common.getCookie(CONSTANTS.RESUME_PATH_COOKIE_NAME) || '';
    }
    common.setCookie(CONSTANTS.RESUME_PATH_COOKIE_NAME, resumePath, CONSTANTS.COOKIES_TIMEOUT_NOT_REMEMBER);
    this.props.fnUpdateResumePagePath(resumePath);
    return resumePath;
  }

  getCurrentPath() {
    const index = window.location.href.indexOf('/' + this.props.locale);
    return window.location.href.substring(index);
  }
  /**
   * Component Name - AppBody
   * Sign in / Register click handler.
   * @param {object} oEvent - Event hanlder
   * @returns {undefined}
   */
  onLogoClick = () => {
    if (this.state.geoBlock) {
      return;
    }
    if (this.props.history.location.pathname !== CONSTANTS.HOME_PATH + this.props.locale) {
      this.bLogoClicked = true;
      this.props.history.push({
        pathname: CONSTANTS.HOME_PATH + this.props.locale
      });
    }
  };
  /**
   * Component Name - AppBody
   * Search input change.
   * @param {object} oEvent - Event hanlder
   * @returns {undefined}
   */
  handleSearchInputText(event) {
    this.setState({
      userInputText: event.target.value
    });
  }
  /**
   * Component Name - AppBody
   * Fetch search items
   * @param {undefined} }
   */
  fnFetchSearchItems() {
    if (this.state.userInputText.length >= 3) {
      this.props.fnSearchUserInput(
        this.props.locale,
        { userInputText: this.state.userInputText, bSearchTerm: true },
        true,
        this.fnUserSearchResponseListError.bind(this),
        (oSearchTerm) => {
          //Send analytics event
          sendEvents(CONSTANTS.SEARCH_CATEGORY, oSearchTerm.userInputText, this.props.location.pathname);
       
          CleverTap_CustomEvents("search", {
            "searched_keyword": this.state.userInputText,
            "country": this.props.countryCode ? this.props.countryCode : localStorage.getItem('country')
          })
        }
      );
    }
    this.props.fnClearUserSearchData();
  }
  /**
   * Component Name - AppBody
   * Search input service failed
   * @param {object} oEvent - Event hanlder
   * @returns {undefined}
   */
  fnUserSearchResponseListError(error) {
    console.error('fnUserSearchResponseListError: ', error);
  }
  /**
   * Component Name - AppBody
   * Key press on search input.
   * @param {object} oEvent - Event hanlder
   * @returns {undefined}
   */
  onSearchInputkeyPress(oEvent) {
    if (
      oEvent.charCode === CONSTANTS.ENTER_KEYCODE &&
      this.props.userSearchResponseList &&
      oEvent.target.value.length >= 3
    ) {
      const userInputText = oEvent.target.value;
      this.props.fnSearchUserInput(
        this.props.locale,
        { userInputText: userInputText, bSearchTerm: true },
        this.fnUserSearchResponseListError.bind(this),
        (oSearchTerm) => {
          //Send analytics event
          sendEvents(CONSTANTS.SEARCH_CATEGORY, oSearchTerm.userInputText, this.props.location.pathname);
        }
      );

      CleverTap_CustomEvents("search", {
        "searched_keyword": this.state.userInputText,
        "country": this.props.countryCode ? this.props.countryCode : localStorage.getItem('country')
      })

      this.props.history.push(`/${this.props.locale}/${CONSTANTS.SEARCH}/${userInputText}/`);
    }
  }
  /**
   * Component Name - AppBody
   * Key Up on search input.
   * @param {object} oEvent - Event hanlder
   * @returns {undefined}
   */
  onSearchInputKeyUp(oEvent) {
    clearTimeout(this.typingTimer);
    if (oEvent.keyCode === CONSTANTS.ESC_KEYCODE) {
      this.props.fnClearUserSearchData();
    } else if (oEvent.keyCode !== CONSTANTS.ENTER_KEYCODE) {
      this.typingTimer = setTimeout(this.fnFetchSearchItems.bind(this), CONSTANTS.TYPING_DELAY);
    }
  }

  /**
   * Component Name - AppBody
   * Key Up on search input.
   * @param {object} oEvent - Event hanlder
   * @returns {undefined}
   */
  onSearchInputKeyDown(oEvent) {
    clearTimeout(this.typingTimer);
  }

  setCookiesPolicyOk(GDPR_DATA, MGC) {  
    localStorage.setItem("Login Count",0)  
    const updatedGoogleAdsValue = !this.state.GoogleAds  ;
        localStorage.setItem("Google Ads", updatedGoogleAdsValue);
        const updatedFacebookAdsValue = !this.state.FacebookAds;
        localStorage.setItem("Facebook Ads", updatedFacebookAdsValue); 
    // 2335285800000 comes in 2093
    common.setGDPRCookie('cookies_accepted', 'true');
    let User_DATA = JSON.parse(common.getServerCookie('COOKIE_USER_OBJECT'))
    //**Defalut GDPR DATA

    let data = {
      performance: true,
      advertising: true,
      googleAnalytics: true,
      cleverTap: true,
      googleAds: true,
      facebookAds: true,   
    }
    if (common.isUserLoggedIn()) {
      let USER_LOGGED__DATA = {
        fname: User_DATA ? User_DATA.firstName : "",
        lname: User_DATA ? User_DATA.lastName : "",
        email: User_DATA ? User_DATA.email : "",
        newsletter: User_DATA ? User_DATA.newslettersEnabled : "",
        promotions: User_DATA ? User_DATA.promotionsEnabled : "",
        country: User_DATA ? User_DATA.countryName : "",
        selectedCountryCode: User_DATA ? User_DATA.countryId : "",
        language: User_DATA ? User_DATA.languageName : "",
        selectedLanguageCode: User_DATA ? User_DATA.languageId : "",
        newsletter1: User_DATA ? User_DATA.privacyPolicy : "",
        newsletter2: User_DATA ? User_DATA.isAdult : "",
        newsletter3: User_DATA ? User_DATA.isRecommend : "",
        firebase: User_DATA ? User_DATA.firebase : "",
        appFlyer: User_DATA ? User_DATA.appFlyer : "",
        aique: User_DATA ? User_DATA.aique : "",
        facebookAds: true,
        isGdprAccepted: true,
        performance: true,
        advertising: true,
        googleAnalytics: true,
        cleverTap: true,
        googleAds: true,

      }
      
      this.props.handleUpdateAccount(
        USER_LOGGED__DATA,
        () => {
          //Data updated successfully
          toast.dismiss();
          toast.success(oResourceBundle.cookie_update_success, {
            position: toast.POSITION.BOTTOM_CENTER
          });
        },
        oError => {
          if (oError) {
            toast.dismiss();
            toast.success(oError.description, {
              position: toast.POSITION.BOTTOM_CENTER
            });
          } else {
            toast.dismiss();
            toast.success(oResourceBundle.something_went_wrong, {
              position: toast.POSITION.BOTTOM_CENTER
            });
          }
        }
      );

      common.setGDPRCookie('GDPR_Cookies', data, CONSTANTS.INFINITE_COOKIE_TIME);

      CleverTap_privacy()
    } else {

      common.setGDPRCookie('GDPR_Cookies', data, CONSTANTS.GDPRCookieExpires);

      CleverTap_privacy()
    }

  }

  /**
   * Component Name - AppBody
   * change logo clicked state
   * @param {Boolean} vValue - value
   * @returns {undefined}
   */
  fnLogoClickedStateChange(bValue) {
    this.bLogoClicked = bValue;
  }
  /**
   * Component Name - AppBody
   * get logo clicked state
   * @param {null}
   * @returns {Boolean}
   */
  fnGetLogoClickedState() {
    return this.bLogoClicked;
  }

  /**
   * Component Name - AppBody
   * Subscribe button click
   * @param {Object} - oEvent
   * @returns {undefined}
   */
  onSubscribeButtonClick = async (oEvent, fromSlider) => {
    let sPath = '';
    const isUserEntitled = await common.isUserSubscribed();
    const resumePath = this.setResumePath();
    if (!common.isUserLoggedIn()) {
  
      sPath = `/${this.props.locale}/${CONSTANTS.LOGIN}`;
      common.setCookie(CONSTANTS.COOKIE_GO_TO_SUBSCRIBE, true, CONSTANTS.INFINITE_COOKIE_TIME);
    } else if (common.isUserLoggedIn() && !isUserEntitled) {
      //if user is logged in and not subscribed go to plans page
      sPath = `/${this.props.locale}/${CONSTANTS.PLANS_DESCRIPTION}`;
    }
    //Send analytics event
    sendEvents(
      CONSTANTS.SUBSCRIPTION_CLICK_CATEGORY,
      CONSTANTS.SUBSCRIPTION_CLICK_ACTION,
      fromSlider === true ? CONSTANTS.LABEL_MAIN_SLIDER : resumePath
    );

    sPath !== '' && common.fnNavTo.call(this, sPath);
  };

  /**
   * Component Name - AppBody
   * Subscribe button click
   * @param {Object} - oEvent
   * @returns {undefined}
   */
  onSubscribeButtonClick1 = async (oEvent, fromSlider) => {
    let sPath = '';
    const isUserEntitled = await common.isUserSubscribed();
    const resumePath = this.setResumePath();
    if (!common.isUserLoggedIn()) {
      sPath = `/${this.props.locale}/${CONSTANTS.LOGIN}`;
      this.props.fnUpdateResumePagePath(`${this.props.locale}/${CONSTANTS.PLANS}`);
      common.setCookie(CONSTANTS.COOKIE_GO_TO_SUBSCRIBE, true, CONSTANTS.INFINITE_COOKIE_TIME);
    } else if (common.isUserLoggedIn() && !isUserEntitled) {
      //if user is logged in and not subscribed go to plans page
      sPath = `/${this.props.locale}/${CONSTANTS.PLANS}`;
    }
    //Send analytics event
    sendEvents(
      CONSTANTS.SUBSCRIPTION_CLICK_CATEGORY,
      CONSTANTS.SUBSCRIPTION_CLICK_ACTION,
      fromSlider === true ? CONSTANTS.LABEL_MAIN_SLIDER : resumePath
    );

    sPath !== '' && common.fnNavTo.call(this, sPath);
  };


  showCookiePolicy = () => {
    common.fnNavTo.call(this, `/${this.props.locale}/${constants.PRIVACY_POLICY}${this.props.locale}`)
  }

  /**
   * Component Name - AppBody
   * renders the UI.
   * @param null
   * @returns {undefined}
   */
  setEssentialPolicyOk(GDPR_DATA, MGC) {
    localStorage.setItem("Login Count",0)
    common.setGDPRCookie('cookies_accepted', 'true'); 
    let User_DATA = JSON.parse(common.getServerCookie('COOKIE_USER_OBJECT'))  

    let data = {     
      facebookAds: false,       
      googleAds: false, 
    }
    console.log(data,"===accept essential");
    if (common.isUserLoggedIn()) {
      let USER_LOGGED__DATA = {       
        facebookAds: false,       
        googleAds: false,
      }
      this.props.handleUpdateAccount(
        USER_LOGGED__DATA,
        () => {
          //Data updated successfully
          toast.dismiss();
          toast.success(oResourceBundle.cookie_update_success, {
            position: toast.POSITION.BOTTOM_CENTER
          });
        },
        oError => {
          if (oError) {
            toast.dismiss();
            toast.success(oError.description, {
              position: toast.POSITION.BOTTOM_CENTER
            });
          } else {
            toast.dismiss();
            toast.success(oResourceBundle.something_went_wrong, {
              position: toast.POSITION.BOTTOM_CENTER
            });
          }
        }
      );

      common.setGDPRCookie('GDPR_Cookies', data, CONSTANTS.INFINITE_COOKIE_TIME);

      CleverTap_privacy()
    } else {

      common.setGDPRCookie('GDPR_Cookies', data, CONSTANTS.GDPRCookieExpires);

      CleverTap_privacy()
    }

  }

  openManageCookiesSettings = () => {
    localStorage.setItem("Login Count",0)
    this.setState({
      cookieSettings: !this.state.cookieSettings
    })
  }


  manageEssentialInfo = () => {
    this.setState({
      showEssential: !this.state.showEssential
    })
  }

  managePerformanceAnalyticsInfo = () => {
    this.setState({
      showPerformance: !this.state.showPerformance
    })
  }

  manageAdvertisingInfo = () => {
    this.setState({
      showAdvertising: !this.state.showAdvertising
    })
  }
   render() {
    const oHandlers = {
      onLanguageButtonClickHandler: this.onLanguageButtonClickHandler.bind(this),
      onMenuButtonClick: this.onMenuButtonClick.bind(this),
      onSearchButtonClick: this.onSearchButtonClick.bind(this),
      onOverlayClick: this.onOverlayClick.bind(this),
      onSignInClick: this.onSignInClick.bind(this),
      onLogoClick: this.onLogoClick.bind(this),
      onSearchInputClicked: this.onSearchInputClicked.bind(this),
      filterShowToggleUserMenu: this.filterShowToggleUserMenu.bind(this),
      onAppBodyClicked: this.onAppBodyClicked.bind(this),
      fnLogoClickedStateChange: this.fnLogoClickedStateChange.bind(this),
      fnGetLogoClickedState: this.fnGetLogoClickedState.bind(this),
      onSubscribeButtonClick: this.onSubscribeButtonClick.bind(this),
      onSubscribeButtonClick1: this.onSubscribeButtonClick1.bind(this),
      // onContestButtonClick:this.onContestButtonClick.bind(this)
    };
    let footerClassName = '';
    if (this.props.playerScreenVisible) {
      footerClassName += ' gone';
    }
    const pagePath = decodeURI(window.location.pathname);
    // console.log(pagePath.toLowerCase(),"---page");
    const contestPage = pagePath.includes('/سفرة-و-سفرة');

 

    //NEW Implemetation
    let IsCookiesAccepted = common.getGDPRCookie('cookies_accepted');
    let IsGDPRCookies = common.getGDPRCookie('GDPR_Cookies') ? true : false
    let GDPRDATA = common.getGDPRCookie('GDPR_Cookies')
    let CurrentData = new Date().getTime()
    let ExpiresTime = GDPRDATA ? GDPRDATA.expiresTime : ""
    // debugger;
    // console.log(ExpiresTime)
     const isUserlogginginweb = common.isUserLoggedIn()
     const isRegisterdeweb = localStorage.getItem("isRegisterdeweb")
     const isLoginCount = localStorage.getItem("Login Count")
     const isRegisteredPlatform = localStorage.getItem("RegisteredPlatform") 
     const  PlatformSpecific= isRegisteredPlatform == "web"; 




    let areCookiesAccepted = false
    if (ExpiresTime && CurrentData >= ExpiresTime) {
      areCookiesAccepted = false
      common.DeleteGDPRCookie('GDPR_Cookies');
      common.DeleteGDPRCookie('cookies_accepted');
    } else {
      areCookiesAccepted = IsCookiesAccepted && IsGDPRCookies ? true : false
    }

    let GDPREssentialData = null

    if (this.props.GDPRPaymentGatewaysList && this.props.GDPRPaymentGatewaysList.payment_providers) {
      GDPREssentialData = this.props.GDPRPaymentGatewaysList
    }   
    return (
      <HandlerContext.Provider value={oHandlers}>
        <Toaster rtl={getDirection(this.props.locale) === CONSTANTS.RTL} />
        <div
          className={"app-body " + getDirection(this.props.locale)}
          dir={getDirection(this.props.locale)}
          ref="app-body"
          onClick={this.onAppBodyClicked.bind(this)}
        >
          {!this.state.isPrivacySite &&
          !this.state.isCookieSite &&
          !this.state.isTermSite ? (
            <AppHeader
              geoBlock={this.state.geoBlock}
              showSearchInput={this.state.showSearchInput}
              showUserMenuDropDown={this.state.showUserMenu}
              location={this.props.location}
              locale={this.props.locale}
              onLanguageButtonClickHandler={this.onLanguageButtonClickHandler.bind(
                this
              )}
              onSearchButtonClick={this.onSearchButtonClick.bind(this)}
              onMenuButtonClick={this.onMenuButtonClick.bind(this)}
              onSignInClick={this.onSignInClick.bind(this)}
              onLogoClick={this.onLogoClick.bind(this)}
              handleSearchInputText={this.handleSearchInputText.bind(this)}
              keyUp={this.onSearchInputKeyUp.bind(this)}
              keyDown={this.onSearchInputKeyDown.bind(this)}
              keyPress={this.onSearchInputkeyPress.bind(this)}
              userInputText={this.state.userInputText}
              HeaderMenu={this.props.aHeaderMenu}
              show={this.state.showMenu}
            />
          ) : (
            <header className="header">
              <div className="header_bottom">
                <div className="contentLeft"></div>
                <div className="contentMiddle">
                  <img className="logo" src={logo} alt="Weyyak logo" />
                </div>
                <div className="contentRight"></div>
              </div>
            </header>
          )}
          {this.props.aMenuItems ? (
            <AppMenu
              menuitems={this.props.aMenuItems.data}
              showUserMenuDropDown={this.state.showUserMenu}
              staticMenuItems={this.props.aStaticMenuItems}
              show={this.state.showMenu}
              closeButtonClick={this.onOverlayClick.bind(this)}
              onSignInClick={this.onSignInClick.bind(this)}
              showCloseBtn={false}
              HeaderMenu={this.props.aHeaderMenu}
              onLanguageButtonClick={this.onLanguageButtonClickHandler.bind(
                this
              )}
            />
          ) : null}
          {this.state.errorOccured && (
            <div className="full-error-message">
              {window.navigator.onLine ? (
                <Spinner />
              ) : (
                oResourceBundle.no_internet_connection
              )}
            </div>
          )}
          {this.props.platformConfig && (
            <div className="page-content">
              {this.state.geoBlock && (
                <section className="geo-blocked">
                  {oResourceBundle.weyyak_unavailable}
                </section>
              )}
              {
                <section
                  className={
                    "home-content" + (this.state.geoBlock ? " gone" : "")
                  }
                >
                  <RoutComponent location={this.props.location} />
                </section>
              }
            </div>
          )}

          {((!areCookiesAccepted &&
            !isUserlogginginweb &&
            !this.state.isCookieSite &&
            !this.state.isTermSite &&
            !this.state.isPrivacySite &&
            !isRegisterdeweb &&
            !this.state.geoBlock) ||
            (!PlatformSpecific && isLoginCount == 1)) && (
            <div
              className={ `cookies-policy-container-v2 ${this.props.locale == "en" ? "": "cookies-policy-containerAr-v2"}`}
            >
              <div 
             
              className="cookies-policy-v2"
              
              >
                <div 
                className="row-container-v2"
                
                >
                  <div 
                  className="latest-cookie-block1-v2">
                  <div className={
                          this.props.locale == "en"
                            ? "cookies-texts-block-v2"
                            : "cookies-textsar-block-v2"
                        }>
                      <div
                       
                        className={
                          this.props.locale == "en"
                            ? "cookies-texts-v2"
                            : "cookies-textsar-v2"
                        }
                      >
                        {isMobile
                          ? oResourceBundle.GDPR_Cookies_Mobile
                          : oResourceBundle.GDPR_Cookies_2}
                      </div>
                      &nbsp;
                      <div
                        
                        className={
                          this.props.locale == "en"
                            ? "cookies-text1-v2"
                            : "cookies-test-v2"
                        }
                      >

                        {isMobile ? "" : oResourceBundle.GDPR_Cookies8}
                      </div>
                    </div>
                    <div>
                      <div
                        
                        className={
                          this.props.locale == "en"
                            ? "termsofuse-v2"
                            : "termsofusear-v2"
                        }
                      >
                        <div className='flexC'>
                        <img src={circle} alt=""
                        className="popupcircle-v2"
                         />
                         <div>
                          <span>{oResourceBundle.GDPR_Cookies_3}</span>
                          <span>                        <Link
                          style={{ color: "white", lineHeight: "100%" }}
                          to={`/${this.props.locale}/static/term-${this.props.locale}`}
                          aria-label={oResourceBundle.Service_terms}
                        >
                          <span>{oResourceBundle.terms_of_use}</span>
                        </Link>{isMobile ? <>&nbsp;</> : <br />}</span>
                        <span 
                        className={isMobile ? "" : "newline-v2"}

                        >
                      
                          {oResourceBundle.And} &nbsp;
                          <Link
                            style={{ color: "white", lineHeight: "100%" }}
                            to={`/${this.props.locale}/static/privacy-${this.props.locale}`}
                            aria-label={oResourceBundle.privacy_policy}
                          >
                            <span>{oResourceBundle.privacypolicies}</span>
                          </Link>
                        </span>

                         </div>
                        </div>

                        
                      </div>
                    </div>
                    <div>
                      <div
                     
                        className={
                          this.props.locale == "en" ? "gdprage-v2" : "gdpragear-v2"
                        }
                      >
                        <div className='flexC'>
                        <img src={circle} alt=""
                         className="popupcircle-v2"
                          />
                          <div>
                            <span>{oResourceBundle.GDPR_Cookies_4}</span>
    
                
                          
                          </div>
                        </div>
                        
                      </div>
                    </div>
                    <div
                      
                      className={
                        this.props.locale == "en"
                          ? "button-group-v2"
                          : "button-groupar-v2"
                      }
                    >
                      <div
                        
                        className={
                          this.props.locale == "en"
                            ? "cookie-btn-group1-v2"
                            : "cookie-btn-group1ar-v2"
                        }
                      >
                        <button
                          
                          className={
                            this.props.locale == "en"
                              ? "new-orange-btn1-v2"
                              : "new-orange-btn1ar-v2"
                          }
                          onClick={this.setCookiesPolicyOk.bind(this)}
                        >
                          {oResourceBundle.Iagree}
                        </button>
                      </div>
                      <div
                        
                        className={
                          this.props.locale == "en"
                            ? "cookie-btn-group1-v2"
                            : "cookie-btn-group1ar-v2"
                        }
                      >
                        <button
                          
                          className={
                            this.props.locale == "en"
                              ? "new-stroke-btn1-v2"
                              : "new-stroke-btn1ar-v2"
                          }
                          onClick={this.setEssentialPolicyOk.bind(this)}
                        >
                          {" "}
                          {oResourceBundle.accept_essential}{" "}
                        </button>
                      </div>
                      <div
                       
                        className={
                          this.props.locale == "en"
                            ? "cookie-btn-group1-v2"
                            : "cookie-btn-group1ar-v2"
                        }
                      >
                        {" "}
                        <button
                         
                          className={
                            this.props.locale == "en"
                              ? "new-stroke-btn2-v2"
                              : "new-stroke-btn2ar-v2"
                          }
                          onClick={this.openManageCookiesSettings}
                        >
                          {" "}
                          {oResourceBundle.Manage_Cookies}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!areCookiesAccepted &&
            this.state.cookieSettings &&
            !contestPage &&
            !this.state.isCookieSite &&
            !this.state.isTermSite &&
            !this.state.isPrivacySite &&
            !this.state.geoBlock && (
              <div
                className="cookies-policy-container-v2"
              >
                <ManageCookies
                  // UserLoginStatus={common.isUserLoggedIn()}
                  // GDPREssentialData={GDPREssentialData}
                  MoreToggle={false}
                  openManageCookiesSettings={this.openManageCookiesSettings}
                  CookiesPolicyOk={this.setCookiesPolicyOk}
                />
              </div>
            )}
          {this.props.loading && !this.props.platformConfig && <Spinner />}
          {!this.state.isPrivacySite &&
          !this.state.isCookieSite &&
          !this.state.isTermSite ? (
            <AppFooter className={footerClassName} />
          ) : (
            <footer className="app-footer">
              <div className="footer-bottom">
                <div>
                  {oResourceBundle.copyright}
                  <span>©</span>
                </div>
              </div>
            </footer>
          )}
        </div>
      </HandlerContext.Provider>
    );
  }
}

/**
 * Component Name - AppBody
 * method that maps state to props.
 * @param {Object} state - state from redux store.
 * @return {Object} - state mapped to props
 */
const mapStateToProps = (state) => {
  return {
    locale: state.locale,
    loading: state.loading,
    platformConfig: state.platformConfig,
    countryCode: state.sCountryCode,
    aMenuItems: state.aMenuItems,
    aStaticMenuItems: state.aStaticMenuItems,
    oPageContent: state.oPageContent,
    playerScreenVisible: state.playerScreenVisible,
    userSearchResponseList: state.userSearchResponseList,
    sResumePagePath: state.sResumePagePath,
    GDPRPaymentGatewaysList: state.aGDPRPaymentGatewaysList,
    oUserAccountDetails: state.oUserAccountDetails,
    aHeaderMenu: state.aHeaderMenu,
    loginDetails: state.loginDetails,
  };
};

/**
 * Component Name - AppBody
 * method that maps state to props.
 * @param {Object} dispatch - dispatcher from store.
 * @return {Object} - dispatchers mapped to props
 */
const mapDispatchToProps = (dispatch) => {
  //dispatch action to redux store
  return {
    fnSendLoginCredentials: (oCredentials, fnSuccess, fnError) => {
      dispatch(
        actionTypes.fnSendLoginCredentials(oCredentials, fnSuccess, fnError)
      );
    },
    //to access use this.props.fnChangeAppDirection();
    /**
     * dispatch method to Redux - used to change language and html layout.
     * @param {string} sCurrentDirection - Current selected locale.
     * @return null
     */
    fnChangeAppDirection: (sCurrentLocale) => {
      dispatch(actionTypes.changeDirection(sCurrentLocale));
    },
    /**
     * dispatch method to Redux - used to change language .
     * @param {string} sCurrentDirection - Current selected locale.
     * @return null
     */
    fnChangeAppLocale: (sCurrentLocale) => {
      dispatch(actionTypes.changeDirection(sCurrentLocale));
    },
    /**
     * dispatch method to Redux - used to fetch the platform config Domains.
     * @param null
     * @return null
     */
    fnFetchPlatformConfig: (sLanguageCode, sCountry, fnSuccess, fnFailure) => {
      dispatch(actionTypes.fnFetchPlatformConfig(sLanguageCode, sCountry, fnSuccess, fnFailure));
    },
    fnHeaderMenu: (slocale, fnSuccess, fnFailed) => {
      dispatch(
        actionTypes.fnHeaderMenu(
          slocale,
          fnSuccess,
          fnFailed
        )
      );
    },
    /**
     * dispatch method to Redux - used to fetch all the menu items.
     * @param null
     * @return null
     */
    fnFetchMenuItems: (sLanguageCode) => {
      dispatch(actionTypes.fnFetchMenuItems(sLanguageCode));
    },
    fnGDPR_PaymentGateWay_Lists: (sCountryCode, sLocale) => {
      dispatch(
        actionTypes.fnGDPR_PaymentGateWay_Lists(
          sCountryCode,
          sLocale
        )
      );
    },
    fnFetchUserDetails: (fnSuccess, fnFailed, bShouldDispatch) => {
      dispatch(
        actionTypes.fnFetchUserDetails(fnSuccess, fnFailed, bShouldDispatch)
      );
    },
    handleUpdateAccount: (currentStateValues, fnSuccess, fnFailed) => {
      dispatch(
        actionTypes.fnHandleUpdateAccount(
          currentStateValues,
          fnSuccess,
          fnFailed
        )
      );
    },
    fnSaveNewUserDetails: newUserDetails => {
      dispatch(actionTypes.fnSaveNewUserDetails(newUserDetails));
    },
    fnClearUserSearchData: () => {
      dispatch(actionTypes.fnClearUserSearchData());
    },
    fnSearchUserInput: (
      sLocale,
      userInputText,
      bUpdateSearchInput,
      fnUserSearchResponseListError,
      fnSearchSuccess
    ) => {
      dispatch(
        actionTypes.fnSearchUserInput(
          sLocale,
          userInputText,
          bUpdateSearchInput,
          fnUserSearchResponseListError,
          fnSearchSuccess
        )
      );
    },
    fnClearSearchItems: () => {
      dispatch({
        type: actionTypes.USER_SEARCH_RESPONSE,
        payload: { userSearchResponseList: [], bUpdateSearchInput: true }
      });
    },
    fnUpdateResumePagePath: (sPath) => {
      dispatch(actionTypes.fnUpdateResumePagePath(sPath));
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppBody));


